import React from 'react';
import {Box, Grid, Typography} from "@mui/material";


const Slider = () => {
    return(
        <Grid container sx={{mt:"-4vh", ml:{xs:"15px", md:"40px"} }} spacing={2}>
            <Grid item xs={12} md={6} sx={{pt:"-24px"}} >
                <Grid container spacing={0} sx={{width:{md:"100%", xs:"80%"}, }}>
                    <Grid item xs={6}><img src={require("./Images/IMG_E5796.JPG")} className="slider-img"/> </Grid>
                    <Grid item xs={6}><img src={require("./Images/IMG_E5797.JPG")} className="slider-img"/> </Grid>
                    <Grid item xs={6}><img src={require("./Images/IMG_E5798.JPG")} className="slider-img"/> </Grid>
                    <Grid item xs={6}><img src={require("./Images/SQMT2382.JPG")} className="slider-img"/> </Grid>
                </Grid>
            </Grid>

            <Grid item xs={11} md={5} sx={{width:{md:"100%", xs:"80%"}, }}>
                <Box sx={{ mr:"8px"}}>
                    <Typography variant="h5" textAlign="center" sx={{color:"white"}}>my passions</Typography>
                    <Typography variant="body2" sx={{color:"white", mt:{sx:"0", md:"88px"}, width:"95%", mr:"auto", lineHeight:"24px"}}>
                        Hi there! <br/>
                        I am tuur, I'm a self thaught web developer/IT student located in Belgium. <br/>
                        At the age of 13 i started out coding ( in scratch :) ) and i immediately knew i had found my passion
                        Since then i've never stopped learning new it related skills. now I am studying software development at kdg in Antwerp.
                        Ofcourse life isn't all about coding (sometimes). I also love travel, art and sports.
                    </Typography>
                </Box>
            </Grid>

        </Grid>
    );
};

export default Slider;
