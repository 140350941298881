import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {Box, Paper, Stack, Typography} from "@mui/material"
import Nav from "./Nav";
import Slider from "./Slider";
import Projects from "./Projects";
import ContactForm from "./contact/ContactForm";
import Contact from "./contact/Contact";

function App() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [open, setState] = useState(false);
    const [degree, setDegree] = useState(-45);
    const myRef:any = useRef()

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const toggleDrawer = (open: boolean) => (event: any) => {
        myRef.current.scrollIntoView()

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;

        }
        setState(open);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);



    return (
        <div className="App">
            <Nav open={open} toggleDrawer={toggleDrawer}/>
            <div ref={myRef} id="home"/>
            <Box sx={{
                background: `linear-gradient(-45deg, #390113 0%, #e2044b 66%,#c90443 66% )`
            }} className="tuur" onClick={() => setState(false)}>
                <Typography variant={"h3"} sx={{
                    mt: "10%",
                    ml: "auto",
                    mr: "auto",
                    width: "80%",
                    textAlign: "center",
                    lineHeight: "64px"
                }}>
                    quality coding <br/> and design by <br/>
                    Tuur </Typography>
                <Typography variant={"h5"}
                            sx={{mt: "1%", ml: "auto", mr: "auto", width: "30%", textAlign: "center", color: "#fff",}}>the
                    T in D&iT</Typography>
                <div className="fadein"/>

                <Box className="discover">
                    <a href="#about">discover my passion</a>
                </Box>
            </Box>

            <div id="about"/>
            <Paper className={scrollPosition > 548 ? "gevers" : "gevers"} elevation={8}
                   sx={{bgcolor: "#8a0f22", borderRadius: "0", height:{xs:"130vh",md:"100vh"}, display:"flex", alignItems:"center"}}>
                <Box>
                    <Slider/>
                    <Box className="project-button">
                        <a href="#projects">discover my projects</a>
                    </Box>
                </Box>

            </Paper>

            <div id="projects"/>
            <Paper className={scrollPosition > 850 ? "gevers2" : "gevers2"} elevation={8}
                   sx={{bgcolor: "#562e36", color: "#fff", display:"flex", alignItems:"center" }}>
                <Box sx={{width:"100%"}}>
                    <Projects/>
                    <Box className="contact-button">
                        <a href="#contact">contact me!</a>
                    </Box>
                </Box>

            </Paper>

            <div id="contact"/>
            <Paper className="contact" elevation={8}
                   sx={{bgcolor: "#5a1c2b", color: "#fff", display:"flex", alignItems:"center",}}>
                <Box>
                    <Contact/>
                    <ContactForm/>
                </Box>

            </Paper>


        </div>
    );
}

export default App;
