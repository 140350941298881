import React, {useRef, useState} from 'react';
import {Box, Button, Card, CardContent, createTheme, Grid, TextField, ThemeProvider, Typography} from "@mui/material";
import emailjs from '@emailjs/browser';

const ContactForm = () => {

    const form: any = useRef();


    const theme = createTheme({
        palette: {
            primary: {
                main: "#a24750",

            }
        }
    });

    const handleSubmit = (e: any) => {
        emailjs.sendForm('service_0nym0g7', 'template', form.current, '7Hn_2iqbnX2E99V5S').then((res) => console.log(res))

    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ml: "16%", mr:"auto"}}>
                <Grid container sx={{width: "95%"}} spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Card sx={{backgroundColor: "#DFE0DF", height:"100%"}}>
                            <form ref={form} onSubmit={handleSubmit}>
                                <CardContent>
                                    <Typography fontWeight={10}>contacteer ons</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <input type="text" name="firstName"/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <input type="email" name="email"/>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <textarea rows={4} name="message"/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" fullWidth
                                                    onClick={handleSubmit}>verstuur</Button>
                                        </Grid>


                                    </Grid>
                                </CardContent>
                            </form>


                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <iframe
                            className="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2493.5362251256215!2d4.9384100158210495!3d51.31965627960476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6b22c574112e7%3A0xc88ecf7c6e7a47c7!2sKruishuisstraat%2066%2C%202300%20Turnhout!5e0!3m2!1snl!2sbe!4v1658071325540!5m2!1snl!2sbe"
                            width="80%" height="100%" style={{border: "0", maxHeight:"40vh"}} allowFullScreen={true} loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"/>
                    </Grid></Grid>
            </Box>

        </ThemeProvider>
    );
};

export default ContactForm;
