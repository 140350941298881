import React from 'react';
import {Typography, Grid, Box, IconButton, ButtonGroup,} from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';


let icons = {
    color: "white",
    "&:hover": {
        color: "#e2b402"
    }

}


const Contact = () => {

    return (
        <Box sx={{width: "90%", ml: "auto", mr: "auto"}}>
            <div>
                <Typography variant="h4" className="titel" textAlign="center">need more info? </Typography>
                <Typography sx={{lineHeight: "32px", fontSize: "24px", mt: "24px", textAlign:{xs:"center", md:"left"}}} className="tekst">
                    If you have a special request (coding related) or you want more information on my skills and
                    services,
                    don't hesitate to send me a message with the form below or contact me on one of my social media
                    accounts!
                </Typography>

                <Typography
                    sx={{
                        textAlign: "center",
                        borderBottom: "2px solid white",
                        width: "60%",
                        margin: "auto",
                        mt: {xs: "50px", md: "0"}
                    }}>Tuur
                    Gevers</Typography>
                <ButtonGroup sx={{justifyContent: "center", display: "flex", pt: "20px"}}>
                    <IconButton className="icon">
                        <a href="https://github.com/tuurGevers">
                            <GitHubIcon sx={icons}/>
                        </a>
                    </IconButton>
                    <IconButton className="icon item1">
                        <a href="https://www.facebook.com/tuur.gevers/">
                            <FacebookIcon sx={icons}/>
                        </a>
                    </IconButton>
                    <IconButton className="icon item2">
                        <a href="https://www.instagram.com/tuurgevers/">
                            <InstagramIcon sx={icons}/>
                        </a>

                    </IconButton>

                </ButtonGroup>
            </div>
        </Box>
    );
};

export default Contact;
