import React from 'react';
import {Box, Typography} from "@mui/material";

const Projects = () => {
    return (


        <Box sx={{
            height:  {md:"100vh", xs:"310"},
            zIndex: "4",
            ml: "8%",
            mr: "8%"
        }}>
            <Typography variant="h5" sx={{width: "50%", pt: "16px", ml: "auto", mr: "auto",}} textAlign="center"> some
                of my
                projects</Typography>
            <Box sx={{
                mt: "4px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
            }}>
                <span>first page</span> <span>first page revisited</span> <span>blogging page</span>
            </Box>
            <Box sx={{
                mt: "-32px",
                display: "flex",
                flexDirection:{md:"row", xs:"column"} ,
                justifyContent: "space-around",
                height: {md:"70vh", xs:"310vh"},
            }}>

                <iframe src="https://beautilicious.netlify.app/" height={"100%"} width="28%"
                        style={{marginTop: "8vh"}}/>

                <iframe src="https://beautiliciousv2.netlify.app/" height={"100%"} width="28%"
                        style={{marginTop: "8vh"}}/>

                <iframe src="https://bnbtravelers.be" height={"100%"} width="28%" style={{marginTop: "8vh",}}/>
            </Box>

        </Box>


    );
};

export default Projects;
