import React, {useState} from 'react';
import {Box, Drawer, IconButton,} from "@mui/material";
import {makeStyles} from "@mui/styles"
import MenuIcon from '@mui/icons-material/Menu';

const useStyles: any = makeStyles(
    {
        paper: {
            backgroundColor: "#000"
        }
    }
)

type props = {
    open: any,
    toggleDrawer: any,
}

const Nav = (props: props) => {
    const classes = useStyles()


    return (
        <div className="nav">
            <IconButton
                aria-label="open drawer"
                onClick={props.toggleDrawer(true)}
                sx={{}}>
                <MenuIcon sx={{color: "white"}}/>
            </IconButton>
            <Drawer
                anchor="left"
                variant="temporary"
                open={props.open}
                onClose={props.toggleDrawer(false)}
                hideBackdrop={false}
                elevation={0}
                sx={{bgColor: "transparent"}}
                PaperProps={{
                    sx: {
                        backgroundColor: "#989595",
                        borderRadius: " 0 100% 100% 0",
                    }
                }}


            >

                <Box sx={{marginTop: "35vh", display: "flex", flexDirection:"column", width:"16vw", textAlign:"center", lineHeight:"32px"}}>
                    <a onClick={props.toggleDrawer(false)} href="#home">home</a>
                    <a onClick={props.toggleDrawer(false)} href="#about">about</a>
                    <a onClick={props.toggleDrawer(false)} href="#projects">projects</a>
                    <a onClick={props.toggleDrawer(false)} href="#contact">contact</a>
                </Box>
            </Drawer>
        </div>
    );
};

export default Nav;
